@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.mobile-menu {
  background-color: #121212; 
  color: white;
  height: 100vh;
  position: fixed;
  z-index: 110;
  width: 100%;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  animation: slideDown 0.5s ease forwards;
  transition: all 0.5s ease;
}

.mobile-menu.hide {
  animation: slideUp 0.5s ease forwards;
}

.close-menu {
  margin-bottom: 100px;
  transition: transform 0.3s ease;
}

.close-menu:hover {
  transform: rotate(90deg); 
}

.mobile-menu .navbar-menu {
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu .mobile-logo {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 20px;
}

.mobile-menu .mobile-logo img {
  width: 130px; 
  transition: transform 0.3s ease-in-out;
}

.mobile-menu .mobile-logo img:hover {
  transform: scale(1.1); 
}

.desc-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.menu-items {
  list-style: none;
  margin-top: 10px;
  margin: 0;
  padding-left: 30px;
}

.menu-items .title {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
}

.menu-items .title a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.menu-items .title a:hover {
  color: #6DECB9; 
  transform: translateX(10px);
}

.menu-items .title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #6DECB9;
  transition: width 0.3s ease;
}

.menu-items .title:hover:after {
  width: 100%; 
}

.menu-items-bot {
  list-style: none;
  margin-top: 10px;
  margin: 0;
}

.menu-items-bot.titles .title {
  font-size: 20px;
}

.menu-items-bot.titles .title.underline a {
  text-decoration: underline;
  color: #6DECB9;
  text-align: center;
}

.menu-items-bot .title a:hover {
  color: #6DECB9;
  text-decoration: underline;
}

.auth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.info {
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  margin-top: 15px;
  color: #bbb;
}
