  /* For Firefox */ 
.project-card {
  progress::-moz-progress-bar { 
      background: #47FFDE !important; 
  } 
}

/* For Chrome or Safari */ 
.project-card {
  progress::-webkit-progress-value { 
      background: #47FFDE !important; 
  }
} 