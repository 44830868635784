:root {
  --font-primary: 'Noto Sans';
  --font-secondary: 'Lato';
  --font-btn: 'ruda';

  --color-white: #fff;
  --color-black: #000;
  --color-green: #6DECB9;
  --color-green-dark: #37D5C5;
  --color-gray-100: #f8f8f8;
  --color-gray-150: #EEEEEE;
  --color-gray-200: #d2d8d4;
  --color-gray-300: #bdbebd;
  --color-gray-400: #919391;
  --color-gray-600: #696969;
  --color-gray-800: #414040;
  --color-input-line: #CFCFCF;
  --shadow-sm: 0px 3px 5px rgba(45, 45, 45, 0.2);

  --padding-content-desktop: 10%;
  --padding-content-mobile: 2rem;

  --device-mobile: 768px;

  font-size: 14px;

}

html {
  // overflow-x: hidden !important;
}

body {
  font-family: var(--font-primary), 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow-x: hidden;
}

.desktop {
  display: block !important;
}

.mobile {
  display: none !important;
}

@media screen and (max-width: 724px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

.PhoneInputInput {
  outline: none;
}

.PhoneInputCountrySelect option {
  background-color: white;
}

.swiper-scrollbar {
  background-color: transparent;
  position: relative !important;
  margin-top: 15px;
}

.swiper-scrollbar-drag {
  background-color: #CDCDCD !important;
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.skeleton {
  opacity: .7;
  animation: skeletonLoading 1s linear infinite alternate;
}

@keyframes skeletonLoading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

//font for titles
.primaryFont {
  font-family: 'Inter', sans-serif !important;
}

//font for texts
.secondaryFont {
  font-family: 'Roboto', sans-serif !important;
}

//font for numbers
.numberFont {
  font-family: 'Inter', sans-serif !important;
}

.bg-projects-slider {
  background: linear-gradient(to bottom, white 44%, #DFDFFF 44%)
}

@media screen and (max-width: 768px) {
  .bg-projects-slider {
    background: linear-gradient(to bottom, white 42%, #DFDFFF 42%)
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.project-offer-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.project-offer-slide {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.project-offer-slide.active {
  opacity: 1;
  transform: translateX(0);
  animation: fadeInRight 1s ease-in-out;
  position: relative;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-in {
  animation: slideInFromRight 1s ease-out forwards;
}

.slide-out {
  animation: slideOutToRight 1s ease-in forwards;
}

.achievement-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    color: #504bfa; 
    transition: color 0.3s ease;
  }
}


.custom-scroll {
  overflow-x: auto; 
}

.custom-scroll::-webkit-scrollbar {
  height: 12px; 
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #ffffff; 
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff; 
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #1a15c6; 
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .custom-scroll {
    overflow-x: scroll; 
  }
}



