.background-register {
  background-image: url("../../../assets/img/auth/register-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  z-index: 1;
}

.background-register::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit; /* Hereda el fondo del elemento padre */
  filter: brightness(0.5); /* Aplica el filtro al pseudo-elemento */
  z-index: -1; /* Coloca el pseudo-elemento detrás de los elementos hijos */
}