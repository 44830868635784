.insights-swiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}

.insights-swiper .swiper-pagination-bullet {
  background-color: #ffffff !important;
  width: 5px !important;
  height: 5px !important;
}

// .insights-swiper .swiper-pagination {
//   position: unset !important;
// }