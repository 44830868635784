.gallery-swiper .swiper-pagination-bullet-active {
  background-color: #FAFAFA !important;
  border: 3px solid #4CF2C3;
}

.gallery-swiper .swiper-pagination-bullet {
  background-color: #FAFAFA !important;
  width: 12px !important;
  height: 12px !important;
  margin: 0 10px !important
}

.gallery-swiper .swiper-pagination {
  position: unset !important;
  margin-top: 10px;
}