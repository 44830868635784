progress[value]{

  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  color: white;

}

progress[value]::-webkit-progress-bar{
  background-color: #0000001A;
  border-radius: 20px;
}

progress[value]::-webkit-progress-value{
  background-color: #000;
  border-radius: 20px;
}