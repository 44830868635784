
/* navbar section */
.navbar{
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  position: fixed;
  width: 100%;
  // opacity: .9;
  // height: 65px;
  overflow: hidden;
}

.navbar-mobile{
  display: none;
}

.navbar-line{
  top: 50px;
  
  @media screen and (max-width: 490px) {
    top: 55px;
  }

  height: 5px;
  display: block;
  position: fixed;
  width: 50%;
  background-color: #000;
  z-index: 60;
}

.navbar .navbar-side{
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.navbar .navbar-logo{
  padding-left: 2%;
}
.navbar .navbar-logo img,
.navbar-mobile .navbar-logo img{
  height: 40px;
  max-width: fit-content;
}

.navbar-avatar{
  cursor: pointer;
}

.navbar .navbar-avatar img,
.navbar-mobile .navbar-avatar img{
  // height: 50px;
}

.navbar .navbar-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  width: 50%;

  @media screen and (max-width: 1500px){
    width: 60%;
  }

}

.navbar .navbar-items{
  width: 100%;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  margin-left: 30px;
  li{
    margin-right: 30px;
    &:last-child{
      margin-right: 0px;
    }
  }
}

.navbar .navbar-items .item{
  display: flex;
  text-decoration: none;
  color: black;
  white-space:nowrap;
}

.navbar .navbar-right{
  padding-right: 2%;
  width: 20%;
  // display: flex;
  // justify-content: space-evenly;
  // align-items: right;

  @media screen and (max-width: 1500px){
    width: 25%;
  }
}

.navbar-cta a{
  color: black;
  font-weight: bold;
  white-space:nowrap;
}



@media screen and (max-width: 1200px) {

  .navbar .navbar-left{
    width: 70%;
  }
  .navbar .navbar-right{
    width: 30%;
  }
}

@media screen and (max-width: 1024px) {

  .navbar{
    display: none;
  }

  .navbar-mobile{
    padding-left: 25px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    position: fixed;
    width: 100vw;
    background-color: #fff;
    height: 60px;
    z-index: 50;

  }

  .mobile-text a {
    text-decoration: none;
    color: var(--color-hite);
  }

  .title a:hover{
    color: var(--color-green);
  }

}

.navbar-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 40;
  }

  .swiper-button-next {
    transform: translateX(-80%);
    padding-left: 40px;
  }

  .swiper-button-prev {
    transform: translateX(-90%);
    padding-right: 20px;
  }
}

