@tailwind base;

@layer base {
  
  *{
    font-family: var(--font-primary), sans-serif;
  }

  a{
    text-decoration: underline;
  }
  
}

@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid #253341;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

.btn-normal {
  @apply bg-gray-900 text-white hover:bg-gray-700 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed;
}

.btn-login {
  @apply bg-gray-900 text-white hover:bg-gray-700 rounded-full px-4 py-2.5 font-medium text-base text-center cursor-pointer duration-300 ease-in-out disabled:cursor-not-allowed;
}

.btn-violet {
  @apply bg-[#B8CCFF] text-black hover:bg-[#B8CCFF]/80 rounded-full px-4.5 py-3 text-lg text-center cursor-pointer duration-300 ease-in-out disabled:cursor-not-allowed;
}

.btn-primary{
  @apply bg-gray-900 text-white hover:bg-gray-700 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline
}

.wrapper-lokl{
  @apply container mx-auto lg:max-w-6xl;
}

.wrapper-lokl-2{
  @apply container mx-auto max-w-[320px] lg:max-w-7xl;
}

.btn-black-full{
  @apply bg-gray-900 text-white hover:bg-gray-700 w-full px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed no-underline
}

.btn-black{
  @apply bg-gray-900 text-white hover:bg-gray-700 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed no-underline
}

.blog-title-md{
  @apply text-3xl font-extrabold font-lato;
}

.blog-title-sm{
  @apply text-2xl font-extrabold font-lato;
}

.btn-outline-white{
  @apply border-2 border-white text-white bg-transparent rounded-full py-2 px-3.5 cursor-pointer;
}

.btn-outline-black{
  @apply border-2 border-black text-black bg-transparent rounded-full py-2 px-8 cursor-pointer hover:bg-black hover:text-white duration-300 ease-in-out;
}

.btn-white{
  @apply bg-white text-black hover:bg-white/75 px-6 rounded-full py-2 font-medium text-base text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline
}

.btn-color-custom{
  @apply  text-black px-6 rounded-full py-2 font-medium text-base text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline
}

.btn-white-med{
  @apply bg-white text-black font-medium text-sm lg:text-xl text-center hover:bg-white/75 px-7 py-2 lg:py-3 rounded-full cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline
}
.carousel {
  position: relative;
  height: 100px; 
  overflow: hidden;
}

.carousel > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease-in-out;
}

.carousel > div.active {
  opacity: 1;
  animation: slideInFromRight 0.5s ease-in-out forwards;
}

.carousel > div.exiting {
  opacity: 0;
  animation: slideOutToLeft 0.5s ease-in-out forwards;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

