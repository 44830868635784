.footer{
  background-color: #000;
  padding-top: 100px;
  width: 100%;
  // display: flex;
  flex-direction: column;
}

.footer .footer-items{
  margin-bottom: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.footer .footer-items .footer-section{
  padding-left: 15px;
}

.footer .footer-items .footer-info {
  width: 27%;
  padding-right: 2%;
}

.footer .footer-items .footer-us{
  width: 15%;
}
.footer .footer-items .footer-sections{
  width: 15%;
}

.footer .footer-items .footer-newsletters{
  width: 35%;
}

.footer .footer-items .icons{
  display: flex;
  justify-content: space-between;
}

.footer .footer-logo{
  padding-bottom: 20px;
}

.footer .footer-slogan{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 20px;
  color: white;
}

.footer-input{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
}

.footer .footer-email{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.footer .footer-phone{
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
}
.footer .footer-contact{
  font-weight: 900;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  color: #fff;
}

.footer .footer-items .footer-section .footer-item{
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.footer .footer-items .footer-section .footer-item > a{
  text-decoration: none;
  color: #fff;
}

.footer .footer-divider{
  border-bottom: 1px solid #7070707e;
  width: 100%;
  margin: 10px 0;
}

.footer .footer-title{
  font-weight: 900;
  font-size: 18px;
  line-height: 26px;
  margin-top: 20px;
  padding-bottom: 5px;
  // border-bottom: 1px solid var(--color-gray-200);
  margin-bottom: 20px;
  color: white;
}

.footer .contact-info{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer .footer-contact-data {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.footer .footer-contact-data .footer-email,
.footer .footer-contact-data .footer-phone{
  white-space: nowrap;
}

.footer .footer-input .input{
  margin-bottom: 10px;
}

.footer .footer-input .input input{
  border: none;
  width: 80%;
  padding: 10px 20px;
  border-radius: 30px 0 0 30px;
  outline: none;
}

.footer .footer-input .input button{
  border: none;
  padding: 10px;
  background-color: white;
  border-radius: 0 30px 30px 0;
  padding-right: 15px;
  color: #000;
}

.footer .footer-copyright{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}

.footer-suscribe{
  display: none;
}


@media screen and (max-width: 1240px) {
  .footer .footer-input .input input {
    width: 60%;
  }
  .footer .footer-contact-data {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 724px) {

  .footer-suscribe{
    padding-top: 50px;
    padding-left: var(--padding-content-mobile);
    padding-right: var(--padding-content-mobile);
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 50px;
  }
  
  .footer-suscribe .footer-title{
    font-size: 23px;
    font-weight: 700;
  }
  
  .footer-suscribe .input{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: white;
  }
  
  .footer-suscribe .input input{
    border: none;
    border-top: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    width: 70%;
    padding: 10px 20px;
    border-radius: 30px 0 0 30px;
    outline: none;
  }
  
  .footer-suscribe .input button{
    border: none;
    padding: 10px;
    background-color: white;
    border-radius: 0 30px 30px 0;
    padding-right: 15px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
  }
  
  .footer {
    padding-left: var(--padding-content-mobile);
    padding-right: var(--padding-content-mobile);
    width: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 0;
  }

  .footer .footer-items{
    flex-wrap: wrap;
  }

  .footer .footer-items .footer-section{
    padding-left: 0px;
  }
   
  .footer .footer-items .footer-info {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }
  

  .footer .footer-slogan {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
  }
  
  .footer .footer-items .footer-sections {
    width: 50%;
    text-align: left;
    padding-left: 20px;
  }
  
  .footer .footer-items .footer-us {
    width: 50%;
    text-align: left;
    padding-right: 20px;
  }
  
  .footer .footer-items .footer-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    width: 60%;
  }

  .footer .footer-items .footer-section .footer-item{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }

  .footer-divider{
    display: none;
  }
  
  .footer .footer-copyright{
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
  .footer .footer-copyright .footer-banca{
    display: 'flex';
    align-items: 'center';

    padding-bottom: 30px;
  }
  
  .footer-newsletters{
    display: none;
  }

}