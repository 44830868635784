input[type=range] {
    height: 20px;
    background: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animation: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #221feb;
    border-radius: 19px;
    border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #221feb;
    height: 18px;
    width: 18px;
    border-radius: 19px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none; /* Propiedad estándar */
    margin-top: -7.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #221feb;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animation: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #221feb;
    border-radius: 19px;
    border: 0px solid #000000;
}

input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #221feb;
    height: 18px;
    width: 18px;
    border-radius: 19px;
    background: #FFFFFF;
    cursor: pointer;
    appearance: none;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animation: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
    appearance: none;
}

input[type=range]::-ms-fill-lower {
    background: #221feb;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
    background: #221feb;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #221feb;
    height: 18px;
    width: 18px;
    border-radius: 19px;
    background: #FFFFFF;
    cursor: pointer;
    appearance: none;
}

input[type=range]:focus::-ms-fill-lower {
    background: #221feb;
}

input[type=range]:focus::-ms-fill-upper {
    background: #221feb;
}
