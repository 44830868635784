.about-investment-swiper .swiper-pagination-bullet-active {
  background-color: #575656 !important;
}

.about-investment-swiper .swiper-pagination-bullet {
  background-color: #959595 !important;
  width: 4px !important;
  height: 4px !important;
}

.about-investment-swiper .swiper-pagination {
  position: unset !important;
}